export default class PatrolTableItem {
    orgCode: number;
    orgCodeName: string;
    userCode: string;
    patrolId: number;
    entryList: any;
    patrolStatus: number;
    patrolStatusName: string;
    producerId: number;
    producerIdName: string;
    patrolPlaceId: number;
    patrolPlaceIdName: number;
    patrolName: string;
    patrolPerson: string;
    personTime: string;
    patrolMonitor: string;
    monitorTime: string;
    patrolOpinion: string; //维修意见
    patrolReason: string; //异常原因
    patrolRemark: string;
    createName: string;
    createTime: string;
    modifyName: string;
    modifyTime: string;
}
