import baseApi from "@/api/baseApi";
import requests from "@/utils/axios";

export default {
    ...baseApi,
    // 模块URL
    modulesUrl: "/aftersale/inspection/patrol",
    // 保存巡检意见结论
    patrolSave(query: any) {
        return requests.post(this.modulesUrl + "/patrolSave", query);
    },
};
