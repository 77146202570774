import { Options } from "vue-class-component";
import BaseTableMixins from "@/mixins/BaseTable";
import BaseTableInterface from "@/mixins/BaseTableInterface";
import PatrolTableItem from "@/entity/aftersale/inspection/PatrolTableItem";
import SelectLevel from "@/modules/select-level/select-level.vue";
import PatrolCheck from "@/modules/patrol-check/patrol-check.vue";
import API from "@/api/aftersale/inspection/patrol";
import OrgAPI from "@/api/system/org";
import UserAPI from "@/api/system/user1";
import ProducerAPI from "@/api/aftersale/inspection/producer";

@Options({
    name: "app-aftersale-inspection-patrol",
    components: {
        "app-patrol-check": PatrolCheck,
        "app-select-level": SelectLevel,
    },
})
export default class Patrol extends BaseTableMixins<PatrolTableItem> implements Partial<BaseTableInterface<PatrolTableItem>> {
    public created(): void {
        this.setInterface(this);
        this.tableRequestItem.lockItem = this.getLockItem();
        this.editTableItem = new PatrolTableItem();
        this.editModalItem = new PatrolTableItem();
    }

    //锁定列数据实体
    public getLockItem(): any {
        return {
            userCode: {
                type: "eq",
                name: "u.user_code",
                title: "发起人",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.userCodeLevelModalOpen,
            },
            orgCode: {
                type: "router",
                name: "patrol_place_id",
                title: "巡检地点",
                parent: this.authUser.orgCode,
                value: null,
                openFn: this.orgCodeLevelModalOpen,
            },
            producerId: {
                type: "router",
                name: "producer_id",
                title: "设备生产商",
                parent: -1,
                value: null,
                openFn: this.producerIdLevelModalOpen,
            },
        };
    }

    public activated(): void {
        this.getList(1);
    }

    //后端API
    public getApi(): any {
        return API;
    }

    //OrgAPI
    get getOrgApi(): any {
        return OrgAPI;
    }

    //UserAPI
    get getUserApi(): any {
        return UserAPI;
    }

    //ProducerAPI
    get getProducerApi(): any {
        return ProducerAPI;
    }

    //添加弹窗配置
    public addModalSetting(): void {
        this.editTableItem = new PatrolTableItem();
        this.editModalItem = new PatrolTableItem();
        this.editModalItem.patrolPlaceId = this.authUser.orgCode;
    }
    //添加提交检测
    public addSubmitChecking(): string {
        if (this.$tools.isEmpty(this.editModalItem.producerId)) return "设备生产商不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.patrolPlaceId)) return "巡检地点不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.patrolName)) return "设备名称不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.patrolPerson)) return "巡检人不能为空！";
        if (this.$tools.isEmpty(this.editModalItem.patrolMonitor)) return "监督人不能为空！";
        return null;
    }

    //查看巡检项
    public openPatrolCheckModal(item: PatrolTableItem) {
        this.editModalItem = item;
        (this.$refs.patrolCheckModal as any).openModal();
    }

    //userCode搜索字段
    private userCodeLevelSearchField: any = [
        { field: "o.org_name", name: "组织名称" },
        { field: "o.org_remark", name: "组织说明" },
        { field: "u.user_name", name: "用户名" },
        { field: "u.real_name", name: "姓名" },
    ];
    //userCode层级选择器打开
    public userCodeLevelModalOpen() {
        (this.$refs.userCodeLevel as any).openModal();
    }
    //userCode层级选择器提交
    public async userCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().userCode : item;
        this.tableRequestItem.lockItem.userCode.value = curItem.value;
        this.tableRequestItem.lockItem.userCode.title = curItem.title;
        this.tableRequestItem.lockItem.userCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //orgCode搜索字段
    private orgCodeLevelSearchField: any = [
        { field: "org_name", name: "名称" },
        { field: "org_remark", name: "说明" },
    ];
    //orgCode层级选择器打开
    public orgCodeLevelModalOpen() {
        (this.$refs.orgCodeLevel as any).openModal();
    }
    //orgCode层级选择器提交
    public async orgCodeLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().orgCode : item;
        this.tableRequestItem.lockItem.orgCode.value = curItem.value;
        this.tableRequestItem.lockItem.orgCode.title = curItem.title;
        this.tableRequestItem.lockItem.orgCode.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    //producerId搜索字段
    private producerIdLevelSearchField: any = [
        { field: "producer_name", name: "名称" },
        { field: "producer_remark", name: "说明" },
    ];
    //producerId层级选择器打开
    public producerIdLevelModalOpen() {
        (this.$refs.producerIdLevel as any).openModal();
    }
    //producerId层级选择器提交
    public async producerIdLevelFunction(item: any): Promise<void> {
        const curItem = this.$tools.isEmpty(item.value) ? this.getLockItem().producerId : item;
        this.tableRequestItem.lockItem.producerId.value = curItem.value;
        this.tableRequestItem.lockItem.producerId.title = curItem.title;
        this.tableRequestItem.lockItem.producerId.parent = curItem.parent;
        await this.getList(1);
        return Promise.resolve();
    }

    get producerIdSelectApi() {
        return {
            api: (query: any) => ProducerAPI.idSelect(query),
            status: "producer_status",
            name: "producer_name",
            id: "producer_id",
            cid: this.editModalItem.producerId,
        };
    }

    get orgCodeSelectApi() {
        return {
            api: (query: any) => OrgAPI.idSelect(query),
            parent: "org_parent_code",
            status: "org_status",
            name: "org_name",
            id: "org_code",
            cid: this.editModalItem.patrolPlaceId,
        };
    }
}
